import { Event, EventTypeLabels } from '@/models/Event';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { DateTime } from 'luxon';

@Component<EventDetailDialog>({
  filters: {
    formatDate: (date: string) => {
      if (! date) {
        return '-';
      }
      return DateTime.fromSQL(date).toFormat('dd-LL-yyyy');
    },
    formatTime: (date: string) => {
      if (! date) {
        return '-';
      }
      return DateTime.fromSQL(date).toFormat('HH:mm');
    },
  },
})
export default class EventDetailDialog extends Vue {
  @Prop()
  protected event!: Event;

  protected mounted() {

  }

  protected goToReport() {
    if (! this.event || ! this.event.report) {
      return;
    }

    this.$router.push(`/reports/${this.event.report.id}`);
  }

  protected get eventTypeLabels() {
    return EventTypeLabels;
  }

  protected close() {
    this.$emit('input', false);
  }
}
