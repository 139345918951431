import { Planner } from '@/support/Planner';
import ErrorHandler from '@/support/ErrorHandler';
import EventDetailDialog from '@/views/Availability/EventDetailDialog/EventDetailDialog.vue';
import { CalendarUser, User } from '@/models/User';
import { AxiosError } from 'axios';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { firstDayOfWeek, lastDayOfWeek, formatDate, setFormattedDatePickerValue, isValidDate, dateErrorMessage } from '@/support/String';
import { DateTime, Settings } from 'luxon';
import { Event, EventTypeLabels, Event as EventModel, EventType } from '@/models/Event';
import { slotDuration, slotLabelFormat, columnsHeaderFormat, calendarPlugins } from '@/support/FullcalendarSettings';
import { renderCustomEvent, renderSimpleEvent } from '@/support/CalendarEventRenderFunctions';
// @ts-ignore
import FullCalendar from '@fullcalendar/vue';
import { Report } from '@/models/Report';
import { ReportTypes } from '@/support/ReportTypes';

@Component<MyPlanning>({
  components: {
    EventDetailDialog,
  },
  filters: {
    parseTime: (date: string) => {
      if (! date) { return ''; }
      return formatDate(date, 'HH:mm');
    },
  },
})
export default class MyPlanning extends Planner {
  public $pageTitle = 'Mijn agenda';

  protected currentWeek: DateTime[] = [];

  protected events: Event[] = [];

  protected reports: Report[] = [];

  protected expert: CalendarUser | null = null;

  protected selectedEvent: any = null;

  protected clickCount = 0;

  protected clickTimer: NodeJS.Timeout | null = null;

  protected date = '';

  protected isLoading = true;

  protected isShowingEventDialog = false;

  protected isWeekView = true;

  public mounted() {
    Settings.defaultLocale = 'nl';
    this.date = DateTime.local().toFormat('yyyy-LL-dd');
    this.initialize();
    this.emitBreadcrumb();
  }

  public async initialize() {
    this.isLoading = true;
    this.getUser();
  }

  protected getUser() {
    this.isLoading = true;

    const organizationId = this.$store.state.Auth.organization.id;
    const userId = this.$store.state.Auth.uuid;

    new User()
      .dmz(organizationId)
      .include(['schedules'])
      .filter('schedule_date_spoof', this.date)
      .find(userId)
      .then(async (user: User) => {
        this.expert = user.parseToCalendarUser();
        this.expert.organization = organizationId;
        const businesshours = user.parseScheduleToBusinessHours();
        this.expert.businessHours = businesshours;
        await this.getEvents();
        const reports = await this.getReports(this.reportsFilter);
        if (! reports) {
          return;
        }
        reports.forEach((report: Report) => {
          if (this.isAllDayEvent(this.events, report, this.expert || undefined) && this.expert) {
            this.expert.events.push(this.generateAllDayEventFromReport(report));
          }
        });
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected renderEvent(eventInfo: any) {
    if (eventInfo && eventInfo.event && eventInfo.event.extendedProps && eventInfo.event.extendedProps.type === 'appointment') {
      renderCustomEvent(eventInfo);
    } else {
      renderSimpleEvent(eventInfo);
    }
  }

  protected handleEventClick(eventInfo: any) {
    if (eventInfo.event?.allDay && eventInfo.event?.id) {
      this.$router.push(`/reports/${eventInfo.event.id}`);
      return;
    }

    this.clickCount += 1;

    if (this.clickCount === 1) {
      this.clickTimer = setTimeout(() => {
        this.clickCount = 0;
      }, 200);
    } else if (this.clickCount === 2) {
      clearTimeout(this.clickTimer as any);
      this.clickCount = 0;
      this.handleDoubleClick(eventInfo);
    }
  }

  protected handleDoubleClick(eventInfo: any) {
    const event = this.events.find((currentEvent: EventModel) => eventInfo.event && eventInfo.event.id && eventInfo.event.id === currentEvent.id);

    if (event) {
      this.selectedEvent = event;
      this.isShowingEventDialog = true;
      return;
    }

    if (this.isCaseMediator) {
      const userReport = this.reports.find((currentReport: Report) => currentReport.uuid && currentReport.uuid === eventInfo.event.id);

      if (! userReport) {
        return;
      }

      this.$router.push(`/reports/${userReport.uuid}`);
    }
  }

  protected goToPreviousWeek() {
    if (this.fullCalendarReference() && this.date) {
      const dateTime = DateTime.fromSQL(this.date);
      if (this.fullCalendarReference().getApi().view.type === 'timeGridWeek') {
        this.date = dateTime.minus({ weeks: 1 }).toFormat('yyyy-LL-dd');
      } else {
        this.date = dateTime.minus({ days: 1 }).toFormat('yyyy-LL-dd');
      }

      this.fullCalendarReference().getApi().gotoDate(this.date);
      this.getUser();
    }
  }

  protected goToNextWeek() {
    if (this.fullCalendarReference() && this.date) {
      const dateTime = DateTime.fromSQL(this.date);
      if (this.fullCalendarReference().getApi().view.type === 'timeGridWeek') {
        this.date = dateTime.plus({ weeks: 1 }).toFormat('yyyy-LL-dd');
      } else {
        this.date = dateTime.plus({ days: 1 }).toFormat('yyyy-LL-dd');
      }

      this.fullCalendarReference().getApi().gotoDate(this.date);
      this.getUser();
    }
  }

  protected goToToday() {
    if (this.fullCalendarReference() && this.date) {
      this.date = DateTime.local().toFormat('yyyy-LL-dd');
      this.fullCalendarReference().getApi().gotoDate(this.date);
    }
  }

  protected goToDate() {
    if (this.fullCalendarReference() && this.defaultDate) {
      this.fullCalendarReference().getApi().gotoDate(this.defaultDate);
      this.getUser();
    }
  }

  private get eventsFilter() {
    const filter: {[key: string]: any} = {
      types: [
        EventType.APPOINTMENT,
        EventType.SICK,
        EventType.HOLIDAY,
        EventType.MEETING,
        EventType.HOUSEVISIT,
        EventType.SPEAKINGROOM,
        EventType.COMMUNITYCENTER,
        EventType.LOSSOFNONRESIDENTIALPROPERTYVALUE,
        EventType.MANURECELLAR,
        EventType.OTHER,
      ],
      not_cancelled: true,
      user: (this.expert as CalendarUser).id,
    };

    if (this.date) {
      const dateTime = DateTime.fromSQL(this.date);
      filter.period = [
        firstDayOfWeek(dateTime).toFormat('yyyy-LL-dd'),
        lastDayOfWeek(dateTime).toFormat('yyyy-LL-dd'),
      ];
    }

    return filter;
  }

  private get reportsFilter() {
    const filter: {[key: string]: string|string[]} = {};

    if (this.date) {
      const dateTime = DateTime.fromSQL(this.date);
      filter.week = firstDayOfWeek(dateTime).toFormat('dd-LL-yyyy');
    }

    if (this.user && this.user.uuid && this.user.type === 'case_mediator') {
      filter.mediators = [this.user.uuid];
    }

    return filter;
  }

  protected async getEvents() {
    this.isLoading = true;

    if (! this.expert) {
      return;
    }
    this.events = [];
    await new EventModel()
      .dmz(this.expert && this.expert.organization ? this.expert.organization : '')
      .include(['report', 'is_mediator_present', 'eventsFilter', 'address'])
      .filter(this.eventsFilter)
      .all()
      .then((events: EventModel[]) => {
        this.events = events;
        if (events.length) {
          (this.expert as CalendarUser).events = [];
          this.parseEvents(events);
        }

        this.isLoading = false;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected parseEvents(events: EventModel[]) {
    if (! this.expert) {
      return;
    }

    events.forEach((event: EventModel) => {
      const fullcalendarEvent: {[key: string]: any} | null = event.fullcalendarEvent;
      if (! fullcalendarEvent) { return; }
      fullcalendarEvent.extendedProps = {
        type: event.type,
        appointment_type: event.appointment_type,
        user: {
          name: (this.expert as CalendarUser).name,
          id: (this.expert as CalendarUser).id,
        },
        organization_id: (this.expert as CalendarUser).organization,
        report: event.report,
        note: event.note,
        is_mediator_present: event.is_mediator_present,
        address: event.address,
        isIMSEvent: new EventModel(event).isIMSEvent,
      };

      if (event.type !== 'appointment') {
        fullcalendarEvent.title = this.eventTypeLabels[event.type as string];
      }

      (this.expert as CalendarUser).events.push(fullcalendarEvent);
    });
  }

  protected changeCalendarView(view: string) {
    if (this.fullCalendarReference()) {
      this.isWeekView = view === 'timeGridWeek';
      this.fullCalendarReference().getApi().changeView(view);
    }
  }

  protected emitBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'Mijn Agenda' },
        ],
      });
  }

  // Date
  protected isEditingDate = false;

  protected dateFormatted: string | null = null;

  protected dateErrorMessage = '';

  protected formatDateFromDatePicker() {
    if (this.defaultDate) {
      this.dateFormatted = setFormattedDatePickerValue(this.defaultDate, 'yyyy-LL-dd', 'dd-LL-yyyy');
      this.dateErrorMessage = ! isValidDate(this.dateFormatted) ? dateErrorMessage : '';
    }
  }

  protected formatDateFromTextField(value: string) {
    this.dateErrorMessage = ! isValidDate(value) ? dateErrorMessage : '';
    this.date = setFormattedDatePickerValue(value);
  }

  protected clearPlannedAtDateFromTextField() {
    this.dateErrorMessage = '';
    this.defaultDate = '';
  }

  @Watch('defaultDate')
  protected dateChanged() {
    this.formatDateFromDatePicker();
  }

  @Watch('isEditingDate')
  protected isEditingDateChanged() {
    if (! this.isEditingDate) {
      this.formatDateFromDatePicker();
    }
  }

  // Getters
  protected get isCaseMediator() {
    return this.$store.state.Auth ? this.$store.state.Auth.hasRole('case_mediator') : false;
  }

  protected get weekNumber() {
    const dateTime = DateTime.fromSQL(this.date);
    return this.date ? dateTime.weekNumber : '';
  }

  protected get headerDate() {
    const dateTime = DateTime.fromSQL(this.date);
    return `(${firstDayOfWeek(dateTime).toFormat('dd-LL-yyyy')} t/m ${lastDayOfWeek(dateTime).toFormat('dd-LL-yyyy')})`;
  }

  protected get slotDuration() {
    return slotDuration;
  }

  protected get slotLabelFormat() {
    return slotLabelFormat;
  }

  protected get columnsHeaderFormat() {
    return columnsHeaderFormat;
  }

  protected get calendarPlugins() {
    return calendarPlugins;
  }

  protected fullCalendarReference(): FullCalendar | null {
    return this.$refs.fullcalendar as FullCalendar;
  }

  protected get defaultDate() {
    return this.date;
  }

  protected set defaultDate(date: string) {
    this.date = date;
  }

  protected get eventTypeLabels() {
    return EventTypeLabels;
  }

  protected get parsedToday() {
    return DateTime.fromSQL(this.defaultDate).toFormat('dd LLLL yyyy');
  }

  protected getDayEvents(day: DateTime) {
    return this.events.filter((event: Event) => event.date === day.toFormat('yyyy-LL-dd') && event.hasType(['free,', 'appointment']));
  }

  get user(): User {
    return this.$store.state.Auth;
  }
}
