var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "miDialog" },
    [
      _c(
        "v-card",
        { staticClass: "miDialog--card" },
        [
          _c(
            "v-card-title",
            [
              _c(
                "v-layout",
                {
                  staticClass: "elementPanel__header",
                  attrs: { row: "", wrap: "" }
                },
                [
                  _c(
                    "v-flex",
                    { staticClass: "header__title", attrs: { sm11: "" } },
                    [
                      !_vm.event.report
                        ? _c("h2", [
                            _vm._v(
                              _vm._s(
                                _vm.event.type
                                  ? _vm.eventTypeLookup[_vm.event.type]
                                  : "Afspraak details"
                              )
                            )
                          ])
                        : _vm._e(),
                      _vm.event.report
                        ? _c("h2", [
                            _vm._v(_vm._s(_vm.event.report.case_number))
                          ])
                        : _vm._e()
                    ]
                  ),
                  _c(
                    "v-flex",
                    { staticClass: "header__close", attrs: { sm1: "" } },
                    [
                      _c(
                        "v-icon",
                        {
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.close()
                            }
                          }
                        },
                        [_vm._v("cancel")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "miDialog--card__text" },
            [
              _c(
                "v-layout",
                { attrs: { wrap: "", "pb-4": "", "align-center": "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs6: "" } },
                    [
                      _c(
                        "v-layout",
                        [
                          _c(
                            "v-flex",
                            { attrs: { shrink: "", "mr-3": "" } },
                            [_c("v-icon", [_vm._v("event")])],
                            1
                          ),
                          _c("v-flex", [
                            _c("span", { staticClass: "event-date" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("formatDate")(_vm.event.starts_at)
                                  ) +
                                  " "
                              ),
                              _c("br"),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("formatTime")(_vm.event.starts_at)
                                  ) +
                                  " - " +
                                  _vm._s(
                                    _vm._f("formatTime")(_vm.event.ends_at)
                                  ) +
                                  " "
                              )
                            ])
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.event.report && _vm.event.report.address
                    ? _c(
                        "v-flex",
                        { attrs: { xs6: "" } },
                        [
                          _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { shrink: "", "mr-3": "" } },
                                [_c("v-icon", [_vm._v("home_work")])],
                                1
                              ),
                              _c("v-flex", [
                                _c("span"),
                                _c("span", [
                                  _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.event.report.address.street
                                            ? _vm.event.report.address.street
                                            : ""
                                        ) +
                                        " " +
                                        _vm._s(
                                          _vm.event.report.address.number
                                            ? _vm.event.report.address.number
                                            : ""
                                        ) +
                                        " "
                                    )
                                  ]),
                                  _c("br"),
                                  _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.event.report.address.postcode
                                            ? _vm.event.report.address.postcode
                                            : ""
                                        ) +
                                        ", " +
                                        _vm._s(
                                          _vm.event.report.address.city
                                            ? _vm.event.report.address.city
                                            : ""
                                        ) +
                                        " "
                                    )
                                  ])
                                ])
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm.event.is_mediator_present
                ? _c(
                    "v-layout",
                    { attrs: { wrap: "", "pb-4": "", "align-center": "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { shrink: "", "mr-3": "" } },
                        [
                          _c("v-icon", { attrs: { color: "success" } }, [
                            _vm._v("check")
                          ])
                        ],
                        1
                      ),
                      _c("v-flex", { attrs: { shrink: "" } }, [
                        _c("span", [_vm._v("Zaakbegeleider aanwezig")])
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm.event.isIMSEvent
                ? _c(
                    "v-layout",
                    { attrs: { "pb-4": "" } },
                    [
                      _vm.event.address
                        ? [
                            _c("span", { staticClass: "addressLine" }, [
                              _vm._v(
                                _vm._s(_vm.event.address.street) +
                                  " " +
                                  _vm._s(_vm.event.address.number)
                              )
                            ]),
                            _c(
                              "span",
                              { staticClass: "addressLine addressLine--small" },
                              [
                                _vm._v(
                                  _vm._s(_vm.event.address.postcode) +
                                    " " +
                                    _vm._s(_vm.event.address.city)
                                )
                              ]
                            )
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                : _vm._e(),
              _vm.event.note
                ? _c(
                    "v-layout",
                    { attrs: { wrap: "", "pb-4": "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { shrink: "", "mr-3": "" } },
                        [_c("v-icon", [_vm._v("event_note")])],
                        1
                      ),
                      _c("v-flex", [
                        _c("span", [_vm._v(_vm._s(_vm.event.note))])
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-layout",
                { attrs: { wrap: "", "justify-end": "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { shrink: "", "text-right": "" } },
                    [
                      _vm.event.report
                        ? _c(
                            "MiButton",
                            {
                              staticClass: "mr-3",
                              attrs: { color: "primary" },
                              nativeOn: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.goToReport($event)
                                }
                              }
                            },
                            [_vm._v(" Naar dossier pagina ")]
                          )
                        : _vm._e(),
                      _c(
                        "MiButton",
                        {
                          attrs: { color: "success" },
                          nativeOn: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.close($event)
                            }
                          }
                        },
                        [_vm._v("Sluiten")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }