import { Vue } from 'vue-property-decorator';
import { Report } from '@/models/Report';
import { CalendarUser } from '@/models/User';
import { ReportTypes } from '@/support/ReportTypes';
import { AxiosError } from 'axios';
import { Event } from '@/models/Event';
import { DateTime } from 'luxon';

export class Planner extends Vue {
  protected async getReports(reportsfilter: Record<string, string | string[]>): Promise<Report[]> {
    const reports = await new Report()
      .filter(reportsfilter)
      .filter({
        planning_blackbar: true,
      })
      .sort('case_number')
      .limit(100)
      .all()
      .catch((error: AxiosError) => {});

    return reports;
  }

  protected isAllDayEvent(events: Event[], report: Report, user?: CalendarUser): boolean {
    const foundEvent = events.find((event) => event?.report?.id === report.uuid);
    if (foundEvent) {
      return false;
    }

    if (user) {
      if (user.id !== report?.mediator?.uuid) {
        return false;
      }

      // If already added no need to add back
      if (user.events.some((event) => event.id === report.uuid)) {
        return false;
      }
    }

    return true;
  }

  protected generateAllDayEventFromReport(report: Report, props: Record<string, unknown> = {}): any {
    const defaultProps = {
      id: report.uuid,
      title: report.case_number,
      allDay: true,
      start: report.planned_at ? DateTime.fromSQL(report.planned_at).toFormat('yyyy-LL-dd') : '',
      end: report.planned_at ? DateTime.fromSQL(report.planned_at).toFormat('yyyy-LL-dd') : '',
      classNames: ['fc-all-day'],
    };

    return { ...defaultProps, ...props };
  }
}
