var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { wrap: "", "mb-2": "" } },
        [
          _c("v-flex", { attrs: { xs12: "", "mb-3": "" } }, [
            _c("h2", { staticClass: "elementSubTitle" }, [
              _vm._v("Mijn Agenda")
            ])
          ]),
          _c(
            "v-flex",
            { staticClass: "elementPanel", attrs: { xs12: "" } },
            [
              _c(
                "v-layout",
                { attrs: { wrap: "", "justify-start": "" } },
                [
                  _c(
                    "v-flex",
                    { staticClass: "calendarContainer", attrs: { xs12: "" } },
                    [
                      _c(
                        "v-layout",
                        {
                          attrs: {
                            "justify-space-between": "",
                            "align-center": ""
                          }
                        },
                        [
                          _c(
                            "v-flex",
                            { attrs: { "flex-starving": "" } },
                            [
                              _c(
                                "v-layout",
                                {
                                  attrs: {
                                    wrap: "",
                                    "justify-space-between": "",
                                    "align-center": ""
                                  }
                                },
                                [
                                  _c("v-flex", [
                                    _c(
                                      "div",
                                      { staticClass: "weekChangeContainer" },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass:
                                              "weekChange weekChange__prev",
                                            on: { click: _vm.goToPreviousWeek }
                                          },
                                          [_vm._v("keyboard_arrow_left")]
                                        ),
                                        _vm.isWeekView
                                          ? _c(
                                              "h2",
                                              {
                                                staticClass:
                                                  "elementSubTitle elementSubTitle__accent"
                                              },
                                              [
                                                _vm._v(
                                                  " Week " +
                                                    _vm._s(_vm.weekNumber) +
                                                    " "
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "header-date"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(_vm.headerDate)
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        !_vm.isWeekView
                                          ? _c(
                                              "h2",
                                              {
                                                staticClass:
                                                  "elementSubTitle elementSubTitle__accent"
                                              },
                                              [_vm._v(_vm._s(_vm.parsedToday))]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass:
                                              "weekChange weekChange__next",
                                            on: { click: _vm.goToNextWeek }
                                          },
                                          [_vm._v("keyboard_arrow_right")]
                                        )
                                      ],
                                      1
                                    )
                                  ]),
                                  _c(
                                    "v-flex",
                                    { attrs: { "pl-3": "" } },
                                    [
                                      _c(
                                        "v-menu",
                                        {
                                          ref: "dialog",
                                          attrs: {
                                            "close-on-content-click": false,
                                            lazy: "",
                                            "nudge-right": "100",
                                            "full-width": "",
                                            "max-width": "290px",
                                            "min-width": "290px"
                                          },
                                          model: {
                                            value: _vm.isEditingDate,
                                            callback: function($$v) {
                                              _vm.isEditingDate = $$v
                                            },
                                            expression: "isEditingDate"
                                          }
                                        },
                                        [
                                          _c("v-text-field", {
                                            staticClass: "input input-date",
                                            attrs: {
                                              slot: "activator",
                                              placeholder: "DD-MM-YYYY",
                                              label: "Kies een datum",
                                              "error-messages":
                                                _vm.dateErrorMessage,
                                              "prepend-icon": "today"
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.formatDateFromTextField(
                                                  $event
                                                )
                                              },
                                              "click:prepend": _vm.goToToday
                                            },
                                            slot: "activator",
                                            model: {
                                              value: _vm.dateFormatted,
                                              callback: function($$v) {
                                                _vm.dateFormatted = $$v
                                              },
                                              expression: "dateFormatted"
                                            }
                                          }),
                                          _c("v-date-picker", {
                                            ref: "picker",
                                            attrs: {
                                              "first-day-of-week": "1",
                                              locale: "nl-nl",
                                              min: "1910-01-01",
                                              color: "#837f16"
                                            },
                                            on: {
                                              change: function($event) {
                                                _vm.isEditingDate = false
                                                _vm.goToDate()
                                              }
                                            },
                                            model: {
                                              value: _vm.defaultDate,
                                              callback: function($$v) {
                                                _vm.defaultDate = $$v
                                              },
                                              expression: "defaultDate"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { "flex-starving": "" } },
                            [
                              _c(
                                "MiButton",
                                {
                                  staticClass: "mr-1",
                                  attrs: {
                                    disabled: !_vm.isWeekView,
                                    compact: "true"
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.changeCalendarView(
                                        "timeGridDay"
                                      )
                                    }
                                  }
                                },
                                [_vm._v(" Day view ")]
                              ),
                              _c(
                                "MiButton",
                                {
                                  staticClass: "ml-1",
                                  attrs: {
                                    disabled: _vm.isWeekView,
                                    compact: "true"
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.changeCalendarView(
                                        "timeGridWeek"
                                      )
                                    }
                                  }
                                },
                                [_vm._v(" Week view ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-layout",
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _vm.isLoading
                                ? _c("LoaderCard", {
                                    staticClass: "overlay",
                                    attrs: {
                                      flat: "",
                                      type: "spinner--center",
                                      minHeight: "300px"
                                    }
                                  })
                                : _vm._e(),
                              _vm.expert
                                ? _c("FullCalendar", {
                                    ref: "fullcalendar",
                                    attrs: {
                                      plugins: _vm.calendarPlugins,
                                      defaultDate: _vm.defaultDate,
                                      nowIndicator: true,
                                      dragScroll: true,
                                      selectable: false,
                                      selectMirror: false,
                                      editable: false,
                                      slotDuration: _vm.slotDuration,
                                      slotLabelFormat: _vm.slotLabelFormat,
                                      columnsHeaderFormat:
                                        _vm.columnsHeaderFormat,
                                      eventOverlap: false,
                                      selectOverlap: false,
                                      locale: "nl",
                                      header: true,
                                      height: 900,
                                      allDaySlot: true,
                                      allDayText: "Mijn dossiers",
                                      events: _vm.expert.events,
                                      businessHours: _vm.expert.businessHours,
                                      selectConstraint: "businessHours",
                                      eventConstraint: "businessHours",
                                      minTime: "06:00",
                                      maxTime: "24:00",
                                      firstDay: 1
                                    },
                                    on: {
                                      eventClick: function($event) {
                                        return _vm.handleEventClick($event)
                                      },
                                      eventRender: _vm.renderEvent
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.isShowingEventDialog
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "30vw", persistent: true },
              model: {
                value: _vm.isShowingEventDialog,
                callback: function($$v) {
                  _vm.isShowingEventDialog = $$v
                },
                expression: "isShowingEventDialog"
              }
            },
            [
              _c("EventDetailDialog", {
                attrs: { event: _vm.selectedEvent },
                model: {
                  value: _vm.isShowingEventDialog,
                  callback: function($$v) {
                    _vm.isShowingEventDialog = $$v
                  },
                  expression: "isShowingEventDialog"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }